import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Stack from "react-bootstrap/Stack";
import Badge from "react-bootstrap/Badge";

import { EyeIconWithCircle } from "../../../../utils/ImportingImages/ImportingImages";
import { ContextAPI } from "../../../../Context/ApiContext/ApiContext";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  formatTime,
  formatDate,
} from "../../../../utils/utilities/utilityFunctions";

const MyVerticallyCenteredModal = (props) => {
  const { mainURL } = useContext(ContextAPI);
  const navigate = useNavigate();
  console.log("prop", props);

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="pt-3 pb-1" closeButton>
        <Modal.Title className="w-100" id="contained-modal-title-vcenter">
          <div className="d-flex justify-content-center align-items-center gap-3">
            <span className="modal-title">Advance Billing Report Details</span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        className="modal-body"
        style={{
          maxHeight: "65vh",
          overflowY: "auto",
        }}
      >
        <div className="d-flex flex-column justify-content-center ">
          <button
            className="custom-btn"
            style={{
              width: "160px",
              padding: "7px 2px",
              marginBottom: "3px",
            }}
          >
            <div
              data-tooltip-id="preview-invoice-tooltip"
              data-tooltip-content="Preview Invoice"
              data-tooltip-place="top"
            >
              <div
                onClick={() => {
                  const invoiceUrl = `${mainURL}/${props?.AdvanceBillingReportData?.invoice_url}`;
                  const rowData = props?.AdvanceBillingReportData;
                  const assignId = rowData?.id;

                  // const clientEmail = JSON.parse(
                  //   props?.AdvanceBillingReportData?.additional_data
                  // )?.primary_email;
                  // navigate("/advance_billing_report_invoice", {
                  //   state: {
                  //     invoice_id: props?.AdvanceBillingReportData.id,
                  //     invoice_url: invoiceUrl,
                  //     row: rowData,
                  //     assignId: assignId || null,
                  //     clientEmail: clientEmail,
                  //   },
                  // });
                }}
              >
                Preview Invoice
              </div>
            </div>
          </button>
          <hr />

          <table className="table table-bordered table-hover">
            <thead>
              <tr>
                <th>Sr no</th>
                <th>Member Name</th>
                <th>Work Description</th>
                <th>Allocated Hours</th>
                <th>Working Time</th>
                <th>Working Date</th>
                <th>Adjustment Hours</th>
                <th>Entry Status</th>
                {/* {props?.AdvanceBillingReportData?.advanced_biiling_time_entries.some(
                  (e) =>
                    e.total_approved_hours !== "" &&
                    e.total_approved_hours !== "0"
                ) ? (
                  <th>Total Approved Hours</th>
                ) : (
                  ""
                )} */}
              </tr>
            </thead>
            <tbody>
              {Array.isArray(
                props?.AdvanceBillingReportData?.advanced_biiling_time_entries
              ) &&
              props?.AdvanceBillingReportData?.advanced_biiling_time_entries
                .length > 0 ? (
                props?.AdvanceBillingReportData?.advanced_biiling_time_entries.map(
                  (entry, index) => (
                    <tr className="text-center" key={index}>
                      <td>{index + 1}</td>
                      <td>
                        {entry.entered_by || (
                          <span style={{ color: "grey" }}>N/A</span>
                        )}
                      </td>

                      <td>
                        {entry.work_description || (
                          <span style={{ color: "grey" }}>N/A</span>
                        )}
                      </td>

                      <td>
                        {formatTime(
                          props.AdvanceBillingReportData.allocated_hours || (
                            <span style={{ color: "grey" }}>N/A</span>
                          )
                        )}
                      </td>
                      <td>
                        {formatTime(
                          entry.working_time || (
                            <span style={{ color: "grey" }}>N/A</span>
                          )
                        )}
                      </td>
                      <td>
                        {" "}
                        {formatDate(
                          entry.working_date || (
                            <span style={{ color: "grey" }}>N/A</span>
                          )
                        )}
                      </td>
                      <td
                        style={{
                          color:
                            entry.adjustment_hours &&
                            entry.adjustment_hours !== "00:00:00"
                              ? "inherit"
                              : "grey",
                        }}
                      >
                        {entry.adjustment_hours &&
                        entry.adjustment_hours !== "00:00:00"
                          ? formatTime(entry.adjustment_hours)
                          : "N/A"}
                      </td>

                      <td>
                        <div className="d-flex justify-content-center align-items-center">
                          <Stack direction="horizontal">
                            {entry.time_entries_status === "approved" ? (
                              <Badge bg="success" className="">
                                Approved
                              </Badge>
                            ) : entry.time_entries_status === "pending" ? (
                              <Badge bg="warning" className="text-dark">
                                Pending
                              </Badge>
                            ) : entry.time_entries_status === "hold" ? (
                              <Badge bg="danger" className="text-dark">
                                Hold
                              </Badge>
                            ) : (
                              <Badge></Badge>
                            )}
                          </Stack>
                        </div>
                      </td>
                      {/* {props?.AdvanceBillingReportData?.advanced_biiling_time_entries.some(
                        (e) =>
                          e.total_approved_hours?.approved_hours !== "" &&
                          e.total_approved_hours?.approved_hours !== "0"
                      ) ? (
                        <td>{entry?.total_approved_hours?.approved_hours}</td>
                      ) : (
                        ""
                      )} */}
                    </tr>
                  )
                )
              ) : (
                <tr className="text-center">
                  <td className="w-100" colSpan={7}>
                    No Data Found !
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <table className="table table-bordered table-hover text-center">
            <thead>
              <tr>
                <th>Total Allocated Hours</th>
                <th>Total Approved Hours</th>
                <th>Total Diff. Hours</th>
                <th>Total Adjusted Hours</th>
              </tr>
            </thead>
            <tbody>
              {props?.AdvanceBillingReportData?.total_allocated_hours ||
              props?.AdvanceBillingReportData?.total_approved_hours ||
              props?.AdvanceBillingReportData?.total_adjust_hours ||
              props?.AdvanceBillingReportData?.total_diff_hours ? (
                <tr className="">
                  <td>
                    {props?.AdvanceBillingReportData?.total_allocated_hours || (
                      <span style={{ color: "grey" }}>N/A</span>
                    )}
                  </td>

                  <td>
                    {props?.AdvanceBillingReportData?.total_approved_hours
                      ?.approved_hours || (
                      <span style={{ color: "grey" }}>N/A</span>
                    )}
                  </td>

                  <td>
                    {props.AdvanceBillingReportData.total_diff_hours || (
                      <span style={{ color: "grey" }}>N/A</span>
                    )}
                  </td>
                  <td>
                    {props?.AdvanceBillingReportData?.total_adjust_hours
                      ?.adjust_hours || (
                      <span style={{ color: "grey" }}>N/A</span>
                    )}
                  </td>
                </tr>
              ) : (
                <tr className="text-center">
                  <td className="w-100" colSpan={7}>
                    No Data Found !
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export const AdvanceBillingReportModal = ({
  AdvanceBillingReportData,
  setIsUpdated,
}) => {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <div
        onClick={() => {
          setModalShow(true);
        }}
      >
        <div className="">
          <EyeIconWithCircle />
        </div>
      </div>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        AdvanceBillingReportData={AdvanceBillingReportData}
        setIsUpdated={setIsUpdated}
      />
    </>
  );
};
